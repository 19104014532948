import React from "react";
import "./ContactInfo.scss";
import {Trans} from "react-i18next";

const ContactInfo = ({ contacts, trademark }) => {
    if (!contacts) {
        return null;
    }

    const contactOrder = [
        "recipient",
        "recipientLine2",
        "street",
        "streetLine2",
        "location",
        "country",
        "email",
        "phone",
        "web"
    ];

    const renderContactDetails = contact => {
        if (!contact) {
            return null;
        }

        const contactDetails = {
            recipient: contact.recipient,
            recipientLine2: contact.recipientLine2,
            street: contact.street,
            streetLine2: contact.streetLine2,
            location: `${contact.state || ""} ${contact.postCode || ""} ${contact.city || ""}`,
            country: contact.country,
            email: contact.email,
            phone: contact.phone,
            web: contact.website
        };

        return contactOrder
            .filter(key => contactDetails[key])
            .map(key => (
                <div key={key} data-label={key.charAt(0).toUpperCase() + key.slice(1)}>
                    {contactDetails[key]}
                </div>
            ));
    }

    return (
        <div className="contact-info-tab">
            {contacts.producer && Object.values(contacts.producer).some(value => value) && (
                <div>
                    <h3><Trans i18nKey="productDetailsPage.manufacturer-contact"/></h3>
                    {trademark && (
                        <div>
                            <Trans i18nKey="productDetailsPage.trademark"/>: {trademark}
                        </div>
                    )}
                    <div className={"trademark"}>
                        {renderContactDetails(contacts.producer)}
                    </div>
                    </div>
                    )}
                    {contacts.representative && Object.values(contacts.representative).some(value => value) && (
                        <div>
                            <h3><Trans i18nKey="productDetailsPage.responsible-contact"/></h3>
                            {renderContactDetails(contacts.representative)}
                        </div>
                    )}
                </div>
            );
            };

            export default ContactInfo;
